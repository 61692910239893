<template>
  <h2 class="text-tekstovyy text-7 font-700 leading-0.8">Отзывы</h2>
  <div
    v-if="ReviewsListRef?.length"
    class="border-t border-black/5 pt-5 mt-6"
  >
    <div class="flex flex-col gap-4 rounded-4.5 bg-white ring-inset ring-1 ring-#f1f1f1 shadow-base shadow-black/8 p-4 w-62.5">
      <p class="text-tekstovyy text-3.75 font-700 leading-0.8">Статистика</p>
      <div class="flex flex-col gap-7 rounded-2.5 bg-#f1f8ec p-3">
        <p class="text-#455862 text-3 leading-1 font-500">Отзывы пользователей</p>
        <p class="text-#455862 text-5 leading-0.8 font-500">{{ ReviewsListRef?.length }}</p>
      </div>
    </div>
    <div class="mt-9 max-w-250">
      <h2 class="text-tekstovyy text-5.5 font-700 leading-0.8">Всего отзывов: {{ ReviewsListRef?.length }} </h2>
      <div class="mt-4">
        <!-- <p class="text-#718997 text-4 leading-0.8 font-500">Вчера</p> -->
        <div class="flex flex-col gap-4 mt-4">
          <template v-for="item in ReviewsListRef">
            <ReviewCard
              :restaurant="selectedRestaurant?.title"
              :review="item"
              :is-answer="false"
              @open-review="(value) => handleOpenModal(value)"
            />
          </template>
        </div>
      </div>
    </div>
    <TransitionModal>
      <LazyReviewModal
        v-if="isModalShow && CurrentReview"
        :restaurant="selectedRestaurant?.title"
        :review="CurrentReview"
        @close="closeModal"
        @success="closeModal"
      />
    </TransitionModal>
  </div>
</template>

<script setup lang="ts">
import { useRestaurants } from '~/composables/partner/restaurants/useRestaurants';
import { useReviews } from '~/composables/partner/reviews/UseReviews';
import type { Review } from '~/composables/partner/reviews/types/ReviewTypes';

const { $toast } = useNuxtApp();
const { closeModal, isModalShow, openModal } = useModal();
const { selectedRestaurant } = useRestaurants();
const { getReviewList } = useReviews();

const handleOpenModal = (value: Review) => {
  CurrentReview.value = value;
  openModal();
};

const ReviewsListRef = ref<Review[] | null>(null);
const CurrentReview = ref<Review | null>(null);
const PutReviewsToRef = async () => {
  const res = await getReviewList({
    restaurant_id: selectedRestaurant.value!.id,
    current_page: 1,
    per_page: 25
  }).catch((err) => {
    $toast.error(checkingErrorOutput(err));
  });
  if (res && res.list.length) {
    ReviewsListRef.value = res.list;
    return;
  }
  ReviewsListRef.value = null;
};

watch(selectedRestaurant, () => {
  if (selectedRestaurant.value) {
    PutReviewsToRef();
  }
}, { immediate: true });

</script>
