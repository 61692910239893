<template>
  <div>
    <h2 class="text-tekstovyy text-7 font-700 leading-0.8">
      <ClientOnly fallback="Ресторан">
        {{ selectedRestaurant?.title }}
      </ClientOnly>
    </h2>

    <div
      v-if="itemsList"
      class="mt-6 grid grid-cols-[282px_1fr] gap-4 items-start min-h-175"
    >
      <div class="flex flex-col gap-4 rounded-4.5 bg-white ring-inset ring-1 ring-#f1f1f1 shadow-base shadow-black/8 px-5 pb-5 pt-6 divide-y divide-black/7">
        <div class="flex flex-col gap-5 pt-5 first:pt-0">
          <p class="text-tekstovyy text-5.5 font-700 leading-0.8">Категории <span class="text-firmennyy">{{ itemsList?.length }}</span></p>
          <div class="flex flex-col gap-6">
            <template v-for="(item, index) in itemsList">
              <p
                class="text-#66757d text-4.5 font-500 leading-0.8 cursor-pointer hover:text-firmennyy transition-colors"
                @click="type = 'category', selectedCategoryIndex = index"
              >
                {{ item.category.title }}
              </p>
            </template>
          </div>
        </div>
        <div
          v-if="stopListItems"
          class="flex flex-col gap-2.5 pt-5 first:pt-0"
        >
          <p
            class="text-tekstovyy text-5.5 font-700 leading-0.8 cursor-pointer"
            @click="type = 'stopList'"
          >
            Стоп лист <span class="text-firmennyy">{{ stopListItems.length }}</span>
          </p>
        <!-- <p class="text-#66757d text-3.5 font-500 leading-0.8">Нет позиций</p> -->
        </div>
      </div>
      <!-- selectedRestaurant прсото чтобы тс не ругался -->
      <MenuCards
        v-if="itemsList && selectedRestaurant && type === 'category'"
        :type="type"
        :category_and_products="itemsList[selectedCategoryIndex]"
        class="self-stretch"
        @update-list="requestMenu({restaurant_id: selectedRestaurant.id}), getStopList({restaurant_id: selectedRestaurant.id})"
      />
      <MenuCards
        v-if="stopListItems && selectedRestaurant && type === 'stopList'"
        :type="type"
        :stop-list-items="stopListItems.flatMap(item => item.product)"
        class="self-stretch"
        @update-list="requestMenu({restaurant_id: selectedRestaurant.id}), getStopList({restaurant_id: selectedRestaurant.id})"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRestaurants } from '~/composables/partner/restaurants/useRestaurants';
import { useMenu } from '~/composables/partner/menu/useMenu';
import type { RestaurantId } from '~/composables/partner/orders/types/PartnerOrdersTypes';
import { storeToRefs, computed } from '#imports';
import { menuStore as store } from '~/stores/menu-store';
import type { RestaurantInfoType } from '~/composables/partner/restaurants/types/RestaurantsTypes';

const { $toast } = useNuxtApp();
const activeCard = ref();

const selectedCategoryIndex = ref(0);

const { selectedRestaurant } = useRestaurants();

const { getMenu, getStopList } = useMenu();

const menuStore = store();

const { itemsList, stopListItems } = storeToRefs(menuStore);

const requestMenu = async (restaurant_id: RestaurantId) => {
  const res = await getMenu(restaurant_id).catch((err) => {
    $toast.error(checkingErrorOutput(err));
  });
};

watch(selectedRestaurant, () => {
  requestMenu({ restaurant_id: selectedRestaurant.value?.id });
  getStopList({ restaurant_id: selectedRestaurant.value?.id });
}, { immediate: true });

const dishs = ref([1, 2, 4, 5]);
const type = ref<'category' | 'stopList'>('category');

</script>
