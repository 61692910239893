import { default as chronologyAk9RhNmE18Meta } from "/home/igorFront/production/sakhfoodpartnerfront/pages/chronology.vue?macro=true";
import { default as helpyomE3lRbpjMeta } from "/home/igorFront/production/sakhfoodpartnerfront/pages/help.vue?macro=true";
import { default as historyZejjP6qWIYMeta } from "/home/igorFront/production/sakhfoodpartnerfront/pages/history.vue?macro=true";
import { default as index4U9ecr68O6Meta } from "/home/igorFront/production/sakhfoodpartnerfront/pages/index.vue?macro=true";
import { default as menuD2V82HJ5pZMeta } from "/home/igorFront/production/sakhfoodpartnerfront/pages/menu.vue?macro=true";
import { default as reports5NPQy51s33Meta } from "/home/igorFront/production/sakhfoodpartnerfront/pages/reports.vue?macro=true";
import { default as reviewss0ETAR3bMhMeta } from "/home/igorFront/production/sakhfoodpartnerfront/pages/reviews.vue?macro=true";
import { default as settingsG7RDUfwcrdMeta } from "/home/igorFront/production/sakhfoodpartnerfront/pages/settings.vue?macro=true";
import { default as workersMltSfS7TrsMeta } from "/home/igorFront/production/sakhfoodpartnerfront/pages/workers.vue?macro=true";
export default [
  {
    name: chronologyAk9RhNmE18Meta?.name ?? "chronology",
    path: chronologyAk9RhNmE18Meta?.path ?? "/chronology",
    meta: chronologyAk9RhNmE18Meta || {},
    alias: chronologyAk9RhNmE18Meta?.alias || [],
    redirect: chronologyAk9RhNmE18Meta?.redirect,
    component: () => import("/home/igorFront/production/sakhfoodpartnerfront/pages/chronology.vue").then(m => m.default || m)
  },
  {
    name: helpyomE3lRbpjMeta?.name ?? "help",
    path: helpyomE3lRbpjMeta?.path ?? "/help",
    meta: helpyomE3lRbpjMeta || {},
    alias: helpyomE3lRbpjMeta?.alias || [],
    redirect: helpyomE3lRbpjMeta?.redirect,
    component: () => import("/home/igorFront/production/sakhfoodpartnerfront/pages/help.vue").then(m => m.default || m)
  },
  {
    name: historyZejjP6qWIYMeta?.name ?? "history",
    path: historyZejjP6qWIYMeta?.path ?? "/history",
    meta: historyZejjP6qWIYMeta || {},
    alias: historyZejjP6qWIYMeta?.alias || [],
    redirect: historyZejjP6qWIYMeta?.redirect,
    component: () => import("/home/igorFront/production/sakhfoodpartnerfront/pages/history.vue").then(m => m.default || m)
  },
  {
    name: index4U9ecr68O6Meta?.name ?? "index",
    path: index4U9ecr68O6Meta?.path ?? "/",
    meta: index4U9ecr68O6Meta || {},
    alias: index4U9ecr68O6Meta?.alias || [],
    redirect: index4U9ecr68O6Meta?.redirect,
    component: () => import("/home/igorFront/production/sakhfoodpartnerfront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: menuD2V82HJ5pZMeta?.name ?? "menu",
    path: menuD2V82HJ5pZMeta?.path ?? "/menu",
    meta: menuD2V82HJ5pZMeta || {},
    alias: menuD2V82HJ5pZMeta?.alias || [],
    redirect: menuD2V82HJ5pZMeta?.redirect,
    component: () => import("/home/igorFront/production/sakhfoodpartnerfront/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: reports5NPQy51s33Meta?.name ?? "reports",
    path: reports5NPQy51s33Meta?.path ?? "/reports",
    meta: reports5NPQy51s33Meta || {},
    alias: reports5NPQy51s33Meta?.alias || [],
    redirect: reports5NPQy51s33Meta?.redirect,
    component: () => import("/home/igorFront/production/sakhfoodpartnerfront/pages/reports.vue").then(m => m.default || m)
  },
  {
    name: reviewss0ETAR3bMhMeta?.name ?? "reviews",
    path: reviewss0ETAR3bMhMeta?.path ?? "/reviews",
    meta: reviewss0ETAR3bMhMeta || {},
    alias: reviewss0ETAR3bMhMeta?.alias || [],
    redirect: reviewss0ETAR3bMhMeta?.redirect,
    component: () => import("/home/igorFront/production/sakhfoodpartnerfront/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: settingsG7RDUfwcrdMeta?.name ?? "settings",
    path: settingsG7RDUfwcrdMeta?.path ?? "/settings",
    meta: settingsG7RDUfwcrdMeta || {},
    alias: settingsG7RDUfwcrdMeta?.alias || [],
    redirect: settingsG7RDUfwcrdMeta?.redirect,
    component: () => import("/home/igorFront/production/sakhfoodpartnerfront/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: workersMltSfS7TrsMeta?.name ?? "workers",
    path: workersMltSfS7TrsMeta?.path ?? "/workers",
    meta: workersMltSfS7TrsMeta || {},
    alias: workersMltSfS7TrsMeta?.alias || [],
    redirect: workersMltSfS7TrsMeta?.redirect,
    component: () => import("/home/igorFront/production/sakhfoodpartnerfront/pages/workers.vue").then(m => m.default || m)
  }
]